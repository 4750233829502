import { Component, OnInit } from '@angular/core';
import { InitialSource } from '../services/tracking.service';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

    readonly homepage = "https://developerakademie.com";

    ngOnInit(): void {
        let redirectUrl = "";
        let currentHref = window.location.href;
        let initialSource: InitialSource = 'facebook';

        if (currentHref.endsWith('/g') || currentHref.endsWith('/g/') || currentHref.endsWith('/info') || currentHref.endsWith('/info/')) {
            initialSource = 'google';
        } else if (currentHref.endsWith('/y') || currentHref.endsWith('/y/')) {
            initialSource = 'youtube';
        } else if (currentHref.endsWith('/ya') || currentHref.endsWith('/ya/')) {
            initialSource = 'youtube-ads';
        } else if (currentHref.endsWith('/t') || currentHref.endsWith('/t/')) {
            initialSource = 'tiktok';
        }

        if (currentHref.endsWith('/tt2yt') || currentHref.endsWith('/tt2yt/')) {
            redirectUrl = 'https://www.youtube.com/Programmierenlernen';
        }
        else if (currentHref.endsWith('.com/y') || currentHref.endsWith('.com/y/')) {
            redirectUrl = this.homepage + "?utm_source=youtube&utm_medium=video&utm_campaign=yt_video&utm_id=regular_video&utm_content=yt_description&initialSource="+initialSource;
        } else if (currentHref.endsWith('.com/ya') || currentHref.endsWith('.com/ya/')) {
            redirectUrl = this.homepage + "?utm_source=youtube&utm_medium=video&utm_campaign=yt_ad&utm_id=paid_video&utm_content=yt_ad_clicked&initialSource="+initialSource;
        } else if (currentHref.endsWith('.com/t') || currentHref.endsWith('.com/t/')) {
            redirectUrl = this.homepage + "?utm_source=tiktok&utm_medium=profile&utm_campaign=tiktok&utm_id=tiktok_video&utm_content=tiktok_profile&initialSource="+initialSource;
        } else {
            const queryParams = window.location.search;
            if (queryParams) {
                redirectUrl = this.homepage + "/" + queryParams + "&initialSource=" + initialSource;
            } else {
                redirectUrl = this.homepage + "/?initialSource=" + initialSource;
            }
        }
        window.location.href = redirectUrl;
    }

}
