import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomFormLibService, ExtraPayload, InitForm } from 'custom-form-lib';
import { TrackingService, UTMParamEnum } from '../services/tracking.service';
import { ThankYouPageEventTrackingService } from '../services/thank-you-page-event-tracking.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-full-stack-apply',
    templateUrl: './full-stack-apply.component.html',
    styleUrl: './full-stack-apply.component.scss'
})
export class FullStackApplyComponent implements OnInit {

    showFooter: boolean = true;
    customForm = inject(CustomFormLibService);
    router = inject(Router);
    trackingService = inject(TrackingService);
    eventTrackingService = inject(ThankYouPageEventTrackingService);

    ngOnInit(): void {
        this.initApplyForm();
        this.trackFirstName();
        this.trackExperience();
        this.checkForDarkMode();
    }

    private initApplyForm() {
        this.customForm.setApplyForm(this.defineApplyFormConfiguration());
    }

    private defineApplyFormConfiguration() {
        const CONFIGURATION = environment.applyFullStackConfig as InitForm;
        CONFIGURATION['extraPayloads'] = this.defineExtraPayload();
        return CONFIGURATION;
    }

    private defineExtraPayload() {
        const extraPayload = this.trackingService.getTrackingPayload() as ExtraPayload[];
        return extraPayload;
    }

    private trackFirstName() {
        const YOUR_NAME_SECTION = this.customForm.applyForm.sections.find((section) => {
            return section.contentId === 'Introduction';
        });
        const YOUR_FIRST_NAME_CONTROL = YOUR_NAME_SECTION?.formGroup.get('firstName');
        YOUR_FIRST_NAME_CONTROL?.valueChanges.subscribe(firstName => this.eventTrackingService.setName(firstName));
    }

    private trackExperience() {
        const YOUR_EXPERIENCE_SECTION = this.customForm.applyForm.sections.find((section) => {
            return section.contentId === 'FullStackExperienceId';
        });
        const YOUR_EXPERIENCE_CONTROL = YOUR_EXPERIENCE_SECTION?.formGroup.get('experience');
        YOUR_EXPERIENCE_CONTROL?.valueChanges.subscribe(experience => this.eventTrackingService.setExperience(experience));
    }

    /**
     * Track Email and Choose Stacks for Google Tag Manager Complete Registration
     * @see https://tagmanager.google.com/#/container/accounts/6004809043/containers/51594281/workspaces/84/tags
     * @param value
     */
    onLastPostSend(value: any) {
        const YOUR_EMAIL_SECTION = this.customForm.applyForm.sections.find((section) => {
            return section.contentId === 'EmailAddress';
        });
        const YOUR_STACKS_SECTION = this.customForm.applyForm.sections.find((section) => {
            return section.contentId === 'Stacks';
        });
        const EMAIL = YOUR_EMAIL_SECTION?.formGroup.get("email")?.value;
        const STACKS = YOUR_STACKS_SECTION?.formGroup.get("stacks")?.value;
        (document.getElementById("completeRegistrationEmail") as HTMLElement).innerText = EMAIL;
        (document.getElementById("completeRegistrationStacks") as HTMLElement).innerText = STACKS.join(",");
        this.router.navigate(['bewerbung-eingegangen']);
    }

    onCurrentSectionChanged(index: number) {
        if (index === 0) this.showFooter = true;
        else this.showFooter = false;
    }

    checkForDarkMode() {
        const darkModeElement = document.querySelector('.dark');
        const bodyElement = document.querySelector('body');

        if (darkModeElement) {
            bodyElement?.classList.add('dark');
        }
    }

}
