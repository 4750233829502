import { Injectable } from '@angular/core';

export declare type InitialSource = 'facebook' | 'google' | 'youtube' | 'youtube-ads' | 'tiktok' | 'unbekannt';

export enum UTMParamEnum {
    UTM_SOURCE = 'utm_source',
    UTM_MEDIUM = 'utm_medium',
    UTM_CAMPAIGN = 'utm_campaign',
    UTM_ID = 'utm_id',
    UTM_TERM = 'utm_term',
    UTM_CONTENT = 'utm_content',
    INITIAL_SOURCE = 'initialSource',
    GCLID = 'gclid',
    WBRAID = 'wbraid',
    FBCLID = 'fbclid'
}

export declare type UTMParamName =
    UTMParamEnum.UTM_CAMPAIGN |
    UTMParamEnum.UTM_CONTENT |
    UTMParamEnum.UTM_ID |
    UTMParamEnum.UTM_MEDIUM |
    UTMParamEnum.UTM_SOURCE |
    UTMParamEnum.UTM_TERM |
    UTMParamEnum.INITIAL_SOURCE |
    UTMParamEnum.GCLID |
    UTMParamEnum.WBRAID |
    UTMParamEnum.FBCLID;

@Injectable({
    providedIn: 'root'
})
export class TrackingService {

    /**
     * Returns tracking payloads
     *
     * @returns { {key: string, value: string }[] }
     */
    getTrackingPayload(): { key: string; value: string; }[] {
        const payload = [
            { key: "fbp", value: this.getCookie("_fbp") },
            { key: "fbc", value: this.getCookie("_fbc") },
            this.getUTMParameter(UTMParamEnum.FBCLID),
            this.getUTMParameter(UTMParamEnum.WBRAID),
            this.getUTMParameter(UTMParamEnum.GCLID),
            this.getUTMParameter(UTMParamEnum.INITIAL_SOURCE, "Unbekannt"),
            this.getUTMParameter(UTMParamEnum.UTM_SOURCE),
            this.getUTMParameter(UTMParamEnum.UTM_CAMPAIGN),
            this.getUTMParameter(UTMParamEnum.UTM_ID),
            this.getUTMParameter(UTMParamEnum.UTM_CONTENT),
            this.getUTMParameter(UTMParamEnum.UTM_MEDIUM),
            this.getUTMParameter(UTMParamEnum.UTM_TERM),
        ];
        return payload;
    }

    /**
     * Get a cookie value by name
     * @param name - The name of the cookie to retrieve
     * @returns The value of the cookie
     */
    getCookie(name: string): string {
        try {
            const nameEQ = `${name}=`;
            const cookies = document.cookie.split(';');
            for (const cookie of cookies) {
                let c = cookie.trim();
                if (c.startsWith(nameEQ)) {
                    return decodeURIComponent(c.substring(nameEQ.length));
                }
            }
            return "";
        } catch (error) {
            return "";
        }
    }

    /**
     * Constructs an object for UTM parameters as needed by consumers.
     * @param utmName - Name of the UTM parameter.
     * @param defaultValue - The default value of the UTM Param if non is given back.
     * @returns Object containing the key and its value. If value is not defined the defaultValue will be set.
     */
    getUTMParameter(utmName: UTMParamName, defaultValue: string = ""): { key: string; value: string } {
        return {
            key: utmName as string,
            value: this.getUTMParameterValue(utmName) || defaultValue
        };
    }

    /**
     * Retrieves a UTM parameter value from URL.
     * @param utmName - Name of the UTM parameter.
     * @returns The value of the UTM parameter.
     */
    getUTMParameterValue(utmName: UTMParamName): string {
        return this.searchUTMParameter(utmName);
    }

    /**
     * Searches the window location for a given UTM parameter.
     * @param utmParameterName - Name of the UTM parameter.
     * @returns The value of the UTM parameter in the URL
     */
    searchUTMParameter(utmParameterName: UTMParamName): string {
        return new URLSearchParams(window.location.search).get(utmParameterName) || "";
    }
}
